import React, { useState } from "react";
import JobOffer from "../components/JobOffer";

const Jobs = ({ english, offers }) => {
  const [search, setSearch] = useState("");

  let locationsPl = [];
  offers.map((offer) => {
    locationsPl.push(offer.locationPl);
  });
  let optionsPl = [...new Set(locationsPl)];
  let locationsEn = [];
  offers.map((offer) => {
    locationsEn.push(offer.locationEn);
  });
  let optionsEn = [...new Set(locationsEn)];

  return (
    <section id="jobs" className="jobs">
      <div className="maincontainer">
        <h2 className="section-name">
          {!english ? "oferty pracy" : "job offers"}
        </h2>
        <h2 className="section-title">
          {!english
            ? "Szukasz idealnej oferty pracy? Mamy ich wiele"
            : "Are you looking for a career? We have many"}
        </h2>
        <div className="jobFilter">
          <h1 className="jobFilter__title">
            {!english ? "Wybierz lokalizację" : "Choose location"}
          </h1>

          <select
            className="jobFilter__select"
            type="select"
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          >
            <option className="jobFilter__option" value="">
              {!english ? "Wszystkie" : "All"}
            </option>
            {!english
              ? optionsPl.map((option, index) => {
                  return (
                    <option key={index} value={option.toLowerCase()}>
                      {option}
                    </option>
                  );
                })
              : optionsEn.map((option, index) => {
                  return (
                    <option key={index} value={option.toLowerCase()}>
                      {option}
                    </option>
                  );
                })}
          </select>
        </div>

        <div className="jobsContainer">
          {offers
            .filter((item) => {
              return search.toLowerCase() === ""
                ? item
                : // : item.locationPl.toLowerCase().includes(search);
                !english
                ? item.locationPl.toLowerCase().includes(search)
                : item.locationEn.toLowerCase().includes(search);
            })
            .map((offer, index) => {
              return (
                <JobOffer
                  key={index}
                  english={english}
                  titlePl={offer.jobTitlePl}
                  titleEn={offer.jobTitleEn}
                  locationPl={offer.locationPl}
                  locationEn={offer.locationEn}
                  link={offer.link}
                />
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Jobs;
