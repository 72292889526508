import React from 'react';
import CsrSlider from '../components/CsrSlider';
import CsrImg from '../assets/images/csr.webp';
import CertImg from '../assets/images/cert_updated.png';

const Csr = ({ english, setEnglish }) => {
  return (
    <section id='csr' className='csr'>
      <div className='maincontainer'>
        <h2 className='section-name'>csr</h2>
        <div>
          <h2 className='csr__content-info--title section-title'>
            {!english
              ? 'Tworząc lepszy i sprawiedliwszy świat'
              : 'Creating a better and fairer world'}
          </h2>
          <p className='csr__content-info--description'>
            {!english
              ? 'Zobowiązujemy się dbać o równość płci, walczyć ze zmianami klimatycznymi i wyróżniać się jako innowacyjny pracodawca przyszłości.'
              : 'We are committed to gender equality, fighting climate change, and being a top employer.'}
          </p>
          <div className='csr__slider '>
            <CsrSlider english={english} setEnglish={setEnglish} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Csr;
