import { createClient } from "contentful";

const useContentful = () => {
  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID,

    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
  });

  const getOffers = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "jobOffer",
        select: "fields",
      });
      const finalEntries = entries.items.map((item) => item.fields);
      return finalEntries;
    } catch (error) {
      console.log(error);
    }
  };
  return { getOffers };
};

export default useContentful;
