import image1 from '../assets/images/slider/1.jpg';
import image2 from '../assets/images/slider/2.jpg';
import image3 from '../assets/images/slider/3.jpg';
import image4 from '../assets/images/slider/4.jpg';
import image5 from '../assets/images/slider/5.jpg';
import image6 from '../assets/images/slider/6.jpg';
import image7 from '../assets/images/slider/7.jpg';
import image8 from '../assets/images/slider/8.jpg';
import image9 from '../assets/images/slider/9.jpg';
import image10 from '../assets/images/slider/10.jpg';
import image11 from '../assets/images/slider/11.jpg';

const images = [
  {
    src: image1,
    textPl: 'Karta Medicover Sport',
    textEn: 'Sport-card in Medicover Sport',
  },
  {
    src: image2,
    textPl: 'Prywatna opieka medyczna',
    textEn: 'Private Medical Care',
  },
  {
    src: image3,
    textPl: 'Platforma wsparcia psychologicznego',
    textEn: 'Mental Health Support Platform ',
  },
  {
    src: image4,
    textPl: 'Ubezpieczenie na życie',
    textEn: 'Private Life insurance',
  },
  {
    src: image5,
    textPl: 'Elastyczny czas pracy',
    textEn: 'Flexible Working Time',
  },
  {
    src: image6,
    textPl: 'Program poleceń pracowniczych',
    textEn: 'Employee Referral Program',
  },
  {
    src: image7,
    textPl: 'Wydarzenia integracyjne',
    textEn: 'Integration Events',
  },
  {
    src: image8,
    textPl: 'Quizy i konkursy',
    textEn: 'Tournaments and Sports Competitions',
  },
  {
    src: image9,
    textPl: 'Relax roomy',
    textEn: 'Relax Zone',
  },

  { src: image10, textPl: 'Brak dress-code’u', textEn: 'No Dress Code' },
  {
    src: image11,
    textPl: 'Inicjatywy charytatywne',
    textEn: 'Charity Initiatives',
  },
];

export default images;
